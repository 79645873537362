<template>
  <form class="career-form" @submit.prevent="$emit('submit',form)">
    <div class="top">
      <h1>Add a new position</h1>

      <div class="form-row">
        <div class="form-group col-md-6">
          <select id="department" class="form-control" v-model="form.department">
            <option key="null" :value="null" disabled>Choose Department</option>
            <option
              v-for="department in departments"
              :key="department.id"
              :value="department.id"
            >{{ department.enName }}</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <select id="country" class="form-control" v-model="form.location">
            <option key="null" :value="null" disabled>Choose Country</option>
            <option
              v-for="country in countries"
              :key="country.id"
              :value="country.id"
            >{{ country.enName }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <input
            type="text"
            class="form-control"
            id="position-title"
            placeholder="Title of Position"
            v-model="form.enTitle"
          >
        </div>
        <div class="form-group col-md-6">
          <input type="text" class="form-control" id="postingUrl" placeholder="URL" v-model="form.postingUrl">
        </div>
      </div>
      <!-- Tab links -->
      <div class="tab">
        <button
          v-for="tab in tabs"
          :key="tab.id"
          class="tablinks"
          :class="{active: tab.isActive}"
          @click.prevent="selectTab(tab)"
        >{{ tab.title }}</button>
      </div>
    </div>
    <div class="bottom">
      <!-- Tab content -->
      <div v-for="tab in tabs" :key="tab.id" :id="tab.id" class="tabcontent" v-show="tab.isActive">
        <h3>Role and Responsibilities</h3>
        <wysiwyg v-model="form[`${tab.id}Description`]"/>
        <h3>Requirements</h3>
        <wysiwyg v-model="form[`${tab.id}Requirements`]"/>
      </div>
      <div class="button-group">
        <button class="btn" @click.prevent="onCancel">Cancel</button>
        <button type="submit" class="btn">Save</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "career-form",
  data: function() {
    return {
      activeTab: "en",
      tabs: [
        { id: "en", title: "English", isActive: true },
        { id: "cn", title: "中文", isActive: false }
      ],
      departments: [],
      countries: [],
      form: {
        department: null,
        location: null,
        enTitle: null,
        postingUrl: null,
        enDescription: null,
        enRequirements: null
      }
    };
  },
  created() {
    //this.getDepartments();
    // this.getCountries();
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.id == selectedTab.id;
      });
    },
    onCancel() {
      this.$router.back();
    },
    resetForm() {
      this.form = {
        department: null,
        location: null,
        enTitle: null,
        postingUrl: null,
        enDescription: null,
        enRequirements: null,
        cnDescription: null,
        cnRequirements: null
      };
    },
    setPost(post){
      this.form = post
    },
    async getDepartments() {
      try {
        let res = await axios({
          url: "http://testdeployd.aezypay.com/departments",
          method: "get"
        });
        this.departments = res.data;
      } catch (err) {
        console.err("error", err);
      }
    },
    async getCountries() {
      try {
        let res = await axios({
          url: "http://testdeployd.aezypay.com/location",
          method: "get"
        });
        this.countries = res.data;
      } catch (error) {
        console.log("error", error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/scss/form";
@import "../../assets/css/scss/tabs";
@import "../../../node_modules/vue-wysiwyg/dist/vueWysiwyg.css";

.career-form {
  // box-shadow: 0px 0px 51px 0px rgba(212,212,212,0.76);
  .top {
    text-align: left;
    padding: 20px;
    padding-bottom: 0;
    background-color: #6779a9;
    border-radius: 15px 15px 0 0;
    h1 {
      color: white;
      font-size: 22px;
      font-weight: 400;
      padding: 20px 0;
    }
    option {
      color: gray;
    }
    .tab {
      margin-top: 50px;
    }
  }
  .bottom {
    background: white;
    border-radius: 0 0 15px 15px;
    .tabcontent {
      padding: 30px;
      text-align: left;

      h3 {
        font-size: 20px;
        color: #6779a9;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 15px;
      }
    }
  }
  .button-group {
    display: flex;
    justify-content: flex-end;
    padding: 30px;

    .btn {
      margin-left: 10px;
      color: grey;
    }
    button[type="submit"] {
      color: #6779a9;
    }
  }
}
</style>
