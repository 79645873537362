<template>
  <div class="admin-careers-edit">
    <Form @submit="onSubmit" ref="editPosting"/>
  </div>
</template>

<script>
import axios from "axios";
import Form from "@/components/admin/CareerForm.vue";

export default {
  name: "admin-careers",
  head: {
    title: { inner: "Admin-Careers-Edit" }
  },
  components: { Form },
  data: function() {
    return {
      post: null
    };
  },
  methods: {
    onSubmit(form) {
      console.log("submitting form", form);
      this.putPosting(form);
    },
    async putPosting(form) {
      let { id, ...details } = form;

      try {
        let res = await axios({
          url: `http://testdeployd.aezypay.com/postings/${id}`,
          method: "put",
          data: details,
          withCredentials: true
        });
        // TODO: success msg
        console.log("res", res);
        this.$router.push({ path: "/admin/careers" });
      } catch (err) {
        console.log("err", error);
      }
    },
  },
  async beforeRouteEnter(to, from, next) {
    await axios.get(`http://testdeployd.aezypay.com/postings/${to.params.id}`)
    .then(res=>{ 
      // set post
      next(vm=> vm.$refs.editPosting.setPost(res.data))
    })
    .catch(err=> {
      console.log(err)
      next(vm=> vm.$router.back())
    })
  },
  beforeRouteLeave(to, from, next) {
    // ...
    if (true) {
      // pop up modal
      // if yes, pass yes to confirm
      // else next(false)
      if (confirm("Are you sure")) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.admin-careers-edit {
  background: #f9f9f9;
  padding: 50px;
  height: 100%;
}
</style>
